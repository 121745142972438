import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import styled from 'styled-components';
import './styles/main.css';

// Import components
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './pages/Home';
import CookieConsent from './components/CookieConsent';
import Impressum from './components/Impressum'; 
import PDFViewer from './components/PdfViewer'; 

// Import contexts
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';

// Import language files
import de from './locales/de.json';
import en from './locales/en.json';

const messages = {
  de: de,
  en: en,
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom, #CAD4DD, #060d25);
`;

const ContentWrapper = styled.main`
  flex: 1;
`;

function AppContent() {
  const { language } = useLanguage();
  
  return (
    <IntlProvider messages={messages[language]} locale={language}>
      <AppWrapper>
        <Header />
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/pdf/:filename" element={<PDFViewer />} />
          </Routes>
        </ContentWrapper>
        <Footer />
      </AppWrapper>
    </IntlProvider>
  );
}

function App() {
  return (
    <LanguageProvider>
      <Router>
        <CookieConsent />
        <AppContent />
      </Router>
    </LanguageProvider>
  );
}

export default App;