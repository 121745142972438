// components/PDFViewer.js
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';

// Configure worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
`;

const Controls = styled.div`
  display: flex;
  gap: 10px;
  margin: 15px 0;
`;

const Button = styled.button`
  background: #060d25;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #1a2642;
  }

  &:disabled {
    background: #cad4dd;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  color: white;
  margin: 0 15px;
`;

const DocumentWrapper = styled.div`
  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page {
    max-width: 100%;
    margin: 10px 0;
    canvas {
      max-width: 100% !important;
      height: auto !important;
    }
  }
`;

const PDFViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <ViewerWrapper>
      <DocumentWrapper>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Loading PDF..."
        >
          <Page 
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </DocumentWrapper>

      {numPages && (
        <Controls>
          <Button
            onClick={() => setPageNumber(page => Math.max(1, page - 1))}
            disabled={pageNumber <= 1}
          >
            Previous
          </Button>
          
          <PageInfo>
            Page {pageNumber} of {numPages}
          </PageInfo>
          
          <Button
            onClick={() => setPageNumber(page => Math.min(numPages, page + 1))}
            disabled={pageNumber >= numPages}
          >
            Next
          </Button>
        </Controls>
      )}
    </ViewerWrapper>
  );
};

export default PDFViewer;