import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const ImpressumWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px;
  font-size: 1.2em;
`;

const ImpressumTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

const ImpressumParagraph = styled.p`
  margin: 10px 0;
`;

const Impressum = () => {
  const intl = useIntl();

  return (
    <ImpressumWrapper>
      <ImpressumTitle>{intl.formatMessage({ id: 'impressum.title' })}</ImpressumTitle>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.companyName' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.shareholder' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.companyType' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.address' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.phone' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.email' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.website' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.companyRegistrationNumber' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.vatId' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.commercialCourt' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.legalForm' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.businessType' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.gisaNumber' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.professionalTitle' })}</ImpressumParagraph>
      <ImpressumParagraph>{intl.formatMessage({ id: 'impressum.imageCredits' })}</ImpressumParagraph>
    </ImpressumWrapper>
  );
};

export default Impressum;
